import { defineComponent, onMounted, reactive, toRefs } from "vue";
import service from "@/api/admin/config";
import tools from "@/utils/tools";
export default defineComponent({
  setup() {
    const state = reactive({
      siteInformation: {
        websiteName: "",
        websiteDescription: "",
        websiteLogo: "",
        websiteIcon: ""
      },
      personalInformation: {
        yourAvatar: "",
        yourEmail: "",
        yourName: ""
      },
      footerSetting: {
        footerAbout: "",
        footerICP: "",
        footerCopyRight: "",
        footerPoweredBy: "",
        footerPoweredByURL: ""
      }
    });
    const methods = {
      findAll() {
        service.findAll().then(res => {
          console.log(res);
          let data = res.data;
          Object.keys(state.siteInformation).forEach(key => {
            if (data[key] !== undefined) {
              state.siteInformation[key] = data[key];
            }
          });
          Object.keys(state.personalInformation).forEach(key => {
            if (data[key] !== undefined) {
              state.personalInformation[key] = data[key];
            }
          });
          Object.keys(state.footerSetting).forEach(key => {
            if (data[key] !== undefined) {
              state.footerSetting[key] = data[key];
            }
          });
        });
      },

      saveParameters(index) {
        let data = {};

        if (index === 1) {
          data = state.siteInformation;
        } else if (index === 2) {
          data = state.personalInformation;
        } else {
          data = state.footerSetting;
        }

        service.updateConfig(data).then(res => {
          if (res.code === 200) {
            tools.message("修改成功");
            methods.findAll();
          }
        });
      }

    };
    onMounted(() => {
      methods.findAll();
    });
    return { ...toRefs(state),
      ...methods
    };
  }

});
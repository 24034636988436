import request from "@/utils/system/request";
import { IPageQuery } from "./pageQuery";

export default {
  findAll() {
    return request({
      url: `/admin/blogConfig/FindAll`,
      method: "get",
    });
  },
  updateConfig(data: object) {
    return request({
      url: `/admin/blogConfig/UpDate`,
      method: "post",
      data
    });
  },
};
